



















































































import { Component, Vue } from "vue-property-decorator";
import * as echarts from "echarts";
import { UserModule } from "@/store/modules/user";
import { orderApi } from "@/api/order";

@Component({
  name: "Dashboard",
})
export default class extends Vue {
  orderCount: any = {};
  orders: any = {};
  fastNavgites = [
    {
      title: "用户",
      path: "/user/user",
      icon: "user",
    },
    {
      title: "角色",
      path: "/user/role",
      icon: "role",
    },
    {
      title: "仪器",
      path: "/ins/ins",
      icon: "ins",
    },
  ];
  onNavgite(item: any) {
    this.$router.push(item.path);
  }
  get showCount() {
    return (key: string) => {
      return this.orderCount[key];
    };
  }
  get formatterLabel() {
    return (order: any) => order?.title?.split("-")?.[0];
  }
  option1 = {
    series: [
      {
        type: "pie",
        data: [
          {
            value: 335,
            name: "数据1",
          },
          {
            value: 1548,
            name: "数据2",
          },
        ],
      },
    ],
  };

  option2 = {
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "40",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 1048, name: "Search Engine" },
          { value: 735, name: "Direct" },
          { value: 580, name: "Email" },
          { value: 484, name: "Union Ads" },
          { value: 300, name: "Video Ads" },
        ],
      },
    ],
  };

  option3 = {
    radar: {
      indicator: [
        { name: "Sales", max: 6500 },
        { name: "Administration", max: 16000 },
        { name: "Information Technology", max: 30000 },
        { name: "Customer Support", max: 38000 },
        { name: "Development", max: 52000 },
        { name: "Marketing", max: 25000 },
      ],
    },
    series: [
      {
        name: "Budget vs spending",
        type: "radar",
        data: [
          {
            value: [4200, 3000, 20000, 35000, 50000, 18000],
            name: "Allocated Budget",
          },
          {
            value: [5000, 14000, 28000, 26000, 42000, 21000],
            name: "Actual Spending",
          },
        ],
      },
    ],
  };

  navgiteTo(path = "") {
    this.$router.push(path);
  }

  async getOrderCount() {
    const res: any = await orderApi.getOrderCount();
    res.forEach((child: any) => {
      const [key] = Object.keys(child);
      this.orderCount[key] = child[key];
    });
    this.orderCount = { ...this.orderCount };
  }
  private async getOrders() {
    try {
      // @ts-ignore
      const res = await orderApi.getOrders({
        page: 1,
        // @ts-ignore
        perPage: 15,
        orderType: "todo",
      });
      this.orders = res?.items;
    } catch (error) {}
  }
  mounted() {
    this.getOrders();
    this.getOrderCount();
    var chartDom1 = document.getElementById("1");
    // @ts-ignore
    var myChart1 = echarts.init(chartDom1, null, {
      width: 300,
      height: 200,
    });
    var chartDom2 = document.getElementById("2");
    // @ts-ignore
    var myChart2 = echarts.init(chartDom2, null, {
      width: 300,
      height: 200,
    });
    var chartDom3 = document.getElementById("3");
    // @ts-ignore
    var myChart3 = echarts.init(chartDom3, null, {
      width: 300,
      height: 200,
    });

    myChart1.setOption(this.option1);
    myChart2.setOption(this.option2);
    myChart3.setOption(this.option3);
  }
}
