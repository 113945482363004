import http, { encodeParams } from '@/utils/request'

export interface IOrderCreate {
  processId: number
  formData: { key: string, value: any }
}

export interface IOrderModify {
  id: number
  status: number
  comment?: string
  formData: { key: string, value: any }
}

export interface IOrderParams {
  enterpriseId: number
  state: string
  orderType: string
  myOrder: number
  q: string
  createDateRange: string
  page: number
  pagePer: number
  createId: number
}

export const orderApi = {
  getOrders: function (params: IOrderParams): Promise<any> {
    return http.get('/order/' + encodeParams(params))
  },
  createOrder: function (param: IOrderCreate): Promise<any> {
    return http.post('/order/', param)
  },
  modifyOrder: function (param: IOrderModify): Promise<{ id: number }> {
    return http.put(`/order/${param.id}`, param)
  },
  getOrderDetail: function (id: number) {
    return http.get(`/order/${id}`)
  },
  getOrderCount: function () {
    return http.get(`/order/count`)
  },
}
